import React from "react"

const Logo = ({ className, title }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 739.1 329">
    <title>{title}</title>
    <g>
      <g>
        <g>
          <g>
            <path fill="#FFFFFF" d="M169.4,157.1c1.4,11.7,3.2,16.1,9.1,17.9c3.6,1.1,7.9,1.4,10.2,1.6c2.3,0,3.4,0.5,3.4,1.8
              c0,1.4-1.4,1.8-4.3,1.8c-14.9,0-29.4,0-49.8-1.1c-3.4-0.2-4.1-1.3-4.1-2.2c0-0.7,0.7-1.4,2-1.8c2.9-1.1,3-2.2,2.3-10.2
              l-6.5-64.2L94.8,170c-4.8,9.1-5.9,10.2-7.3,10.2c-2,0-3-1.8-8.1-9.7c-6.3-10-17.2-30.5-24-41.9c-3-5.2-10.9-19.9-14.3-26
              l-5.2,50.6c-0.7,7.7-0.7,11.7-0.7,14.3c0,5.2,1.8,7.3,6.3,8.2c2.7,0.5,4.3,0.7,6.6,0.9c2.7,0,3.2,0.5,3.2,1.6c0,1.4-0.7,2-3.6,2
              c-7.9,0-17.7-0.5-23.1-0.5c-5.6,0-14,0.5-20.6,0.5c-1.8,0-3.9,0-3.9-1.8c0-1.1,1.1-1.8,3.6-1.8c1.4-0.2,3.4-0.2,5.4-0.5
              c5.2-1.1,7-5.2,8.8-17L34.1,48.8c0.7-5.4,2-7,3.8-7c2,0,3.2,1.8,7,7.3l51.3,85.9l49.1-86.8c2.9-4.7,4.3-6.5,6.1-6.5
              s2.9,2.5,3.4,7L169.4,157.1z"/>
            <path fill="#FFFFFF" d="M179.8,122.2c0-32.4,22.2-61.5,69.4-61.5c40.9,0,66.5,27.4,66.5,57.9c0,36.4-25.3,63.8-67.6,63.8
              C200.4,182.4,179.8,149.6,179.8,122.2z M281.3,125.7c0-23.7-8.6-54.7-37.6-54.7c-13.8,0-29.4,7-29.4,43.4
              c0,28.7,11.5,57.2,38.2,57.2C262.3,171.6,281.3,167.9,281.3,125.7z"/>
            <path fill="#FFFFFF" d="M330,107.8c0-24.2-0.2-26.5-0.5-31.5c-0.4-6.6-3.2-8.8-6.6-9.1c-2.9-0.5-4.3-0.5-5.6-0.7
              c-2,0-2.7-0.9-2.7-1.8c0-0.9,0.5-1.8,3.2-1.8c6.5,0,22.9,0.5,24.9,0.5c2.7,0,19.5-0.5,27.6-0.5c16.1,0,27.2,1.6,33.7,5.2
              c8.6,4.3,15.2,12,15.2,24.2s-8.2,22-21.3,30.5c8.4,12.9,19.9,29.9,29.8,40.9c8.2,9.5,12,11.8,17,12.5c1.6,0.4,3,0.4,4.3,0.5
              c1.6,0.2,2.5,1.1,2.5,2c0,1.4-1.8,1.6-3.2,1.6h-14c-18.6,0-26.4-1.8-32.3-5.6c-4.7-3-9.7-9.3-15.4-18.6
              c-7.5-12.5-10.2-17.7-13.3-22.4c-0.2-0.5-0.9-0.9-1.8-0.9l-10.8-0.2c-0.7,0-1.3,0.4-1.3,1.3v9.5c0,11.7,0.4,19.2,0.7,23.8
              c0.4,3.9,1.3,7.2,6.1,8.4c2.2,0.7,5.4,0.9,7.3,1.1c2.9,0.2,2.9,1.1,2.9,2c0,0.9-0.7,1.6-3,1.6c-10.9,0-29.9-0.5-30.8-0.5
              c-1.8,0-18.6,0.5-23.8,0.5c-2.7,0-3.2-0.9-3.2-2c0-0.9,1.1-1.6,2.9-1.6c1.4-0.2,3.8-0.2,5.6-0.5c3.2-0.7,4.7-3.4,5-9
              c0.7-6.5,0.9-16.9,0.9-32.1V107.8z M359.6,117.5c0,1.1,0.7,1.8,1.4,2.3c3,1.6,8.6,2.2,13.3,2.2c3.6,0,7.3-0.7,9.9-2.5
              c2.9-2.2,5.6-8.4,5.6-18.5c0-22-15.1-27.2-23.1-27.2c-2.3,0-5,0-5.9,0.5c-0.7,0.4-1.1,0.5-1.1,1.8V117.5z"/>
            <path fill="#FFFFFF" d="M454.1,107.8c0-25.8-0.2-28.7-0.5-33.7c-0.4-3.9-2-6.5-5.7-7.2c-1.4-0.4-3.2-0.4-4.8-0.5
              c-2.3-0.2-2.7-1.1-2.7-2s0.9-1.6,3.4-1.6c9.7,0,17.2,0.5,24,0.5c2.9,0,44.1-0.2,46.6-0.2c3,0,6.5-0.2,9.1-0.7
              c1.1-0.4,2-0.5,2.5-0.5c0.9,0,1.3,0.7,1.1,1.6c-0.4,2-0.9,3.9-1.3,7.5c-0.2,3.9-0.4,7.2-0.9,16.9c0,1.3-0.7,2.5-1.8,2.5
              c-0.9,0-1.6-0.7-1.8-2.2c-0.4-2.2-0.4-3.4-0.9-5.7c-0.9-3.6-2.9-5.7-10.6-6.5c-5.9-0.4-19-0.7-24-0.7c-0.9,0-1.3,0.5-1.3,1.6
              v32.8c0,1.1,0.2,1.4,1.3,1.4c4.8,0,16,0,22.2-0.2c8.8-0.4,10.9-0.9,12.9-3.9c0.7-1.4,1.4-2,2.2-2c1.1,0,1.3,1.1,1.3,2
              c0,1.8-1.1,8.6-1.3,14.3c-0.2,3.4-0.4,11.7-0.4,13.4c0,2.2-0.5,2.7-1.6,2.7c-0.9,0-1.6-0.7-1.8-2.5c-0.2-0.9-0.2-2-0.4-3
              c-0.9-4.8-2.7-7.7-11.1-8.2c-5-0.4-17.2-0.5-22.2-0.5c-0.9,0-1.1,0.4-1.1,1.3v11.3c0,5.2-0.2,12.5,0,18.8
              c0.2,10.9,2.2,13.4,20.4,13.4c4.1,0,10.4-0.2,14.9-1.8c4.5-1.8,6.3-5.6,7.7-12.7c0.4-1.8,1.1-2.7,2.2-2.7c1.3,0,1.4,1.3,1.4,3.2
              c0,6.8-1.6,19.4-2.3,22.4c-1.3,3.9-2.5,4.1-8.4,4.1c-24,0-35.5-0.9-52.5-0.9c-7.5,0-15.2,0.5-24.4,0.5c-3,0-3.8-0.9-3.8-2
              c0-0.7,0.5-1.6,3.2-1.6c1.6-0.2,2.9-0.2,4.7-0.5c5.2-0.9,5.7-7,6.3-17.9c0-5.9,0.2-14.5,0.2-23.1V107.8z"/>
            <path fill="#FFFFFF" d="M581.2,135.2c0,14.7-0.5,26,2,29.4c2.3,3.2,5.2,3.8,18.6,3.8c9,0,13.3-0.2,17.7-1.8
              c4.5-1.8,7.9-5.6,9.9-14.2c0.4-1.8,0.9-3.2,2.3-3.2c1.1,0,1.4,1.1,1.4,3.2c0,3.9-2.3,19.5-3.9,24c-1.4,4.1-2.5,4.1-8.4,4.1
              c-24.2,0-39.1-0.9-56.1-0.9c-7.5,0-15.6,0.5-24.7,0.5c-2.5,0-3.8-0.5-3.8-2c0-0.7,0.9-1.6,3-1.6c1.6-0.2,2-0.2,3.8-0.4
              c6.5-1.1,7.2-3.6,7.7-17.9c0-6.1,0.2-14.7,0.2-23.3v-27.2c0-25.8-0.2-27.6-0.5-32.6c-0.4-4.3-1.8-6.8-5.6-7.9
              c-1.4-0.4-3.6-0.7-5.9-0.9c-1.8-0.2-3.2-0.5-3.2-1.8c0-1.3,1.3-1.8,4.5-1.8c9.7,0,17.6,0.5,24.4,0.5c0.9,0,21.7-0.5,27.4-0.5
              c2.9,0,3.6,0.7,3.6,1.6c0,1.4-1.4,2-2.9,2c-1.8,0.2-2.5,0.2-4.8,0.7c-4.5,0.7-5.7,3.6-6.1,9.1c-0.4,5-0.5,7.3-0.5,33.5V135.2z"
              />
            <path fill="#FFFFFF" d="M678.6,135.2c0,14.7-0.5,26,2,29.4c2.3,3.2,5.2,3.8,18.6,3.8c9,0,13.3-0.2,17.7-1.8
              c4.5-1.8,7.9-5.6,9.9-14.2c0.4-1.8,0.9-3.2,2.3-3.2c1.1,0,1.4,1.1,1.4,3.2c0,3.9-2.3,19.5-3.9,24c-1.4,4.1-2.5,4.1-8.4,4.1
              c-24.2,0-39.1-0.9-56.1-0.9c-7.5,0-15.6,0.5-24.7,0.5c-2.5,0-3.8-0.5-3.8-2c0-0.7,0.9-1.6,3-1.6c1.6-0.2,2-0.2,3.8-0.4
              c6.5-1.1,7.2-3.6,7.7-17.9c0-6.1,0.2-14.7,0.2-23.3v-27.2c0-25.8-0.2-27.6-0.5-32.6c-0.4-4.3-1.8-6.8-5.6-7.9
              c-1.4-0.4-3.6-0.7-5.9-0.9c-1.8-0.2-3.2-0.5-3.2-1.8c0-1.3,1.3-1.8,4.5-1.8c9.7,0,17.6,0.5,24.4,0.5c0.9,0,21.7-0.5,27.4-0.5
              c2.9,0,3.6,0.7,3.6,1.6c0,1.4-1.4,2-2.9,2c-1.8,0.2-2.5,0.2-4.8,0.7c-4.5,0.7-5.7,3.6-6.1,9.1c-0.4,5-0.5,7.3-0.5,33.5V135.2z"
              />
          </g>
        </g>
        <g>
          <g>
            <path fill="#FFFFFF" d="M16.1,261.1l-8-0.9v-3.7h19.4l10.3,32.9l2.4,9.3l2.2-9.3l10.2-32.9h19.2v3.7l-8,0.9l5.8,45.4l6.4,0.8v3.6
              H55v-3.6l6.6-0.7l-3.3-32.8l-1.5-14.1l-3.1,10.5l-12.2,38.2h-4.8l-11.8-35l-4.3-13.8l-1.3,14.2l-2.9,32.8l7.2,0.7v3.6H4.5v-3.6
              l6.2-0.7L16.1,261.1z"/>
            <path fill="#FFFFFF" d="M81.4,305.9c-3.1-3.8-4.6-9-4.6-15.4c0-4.2,0.8-8,2.4-11.2s3.9-5.7,6.8-7.5c2.9-1.8,6.2-2.6,9.9-2.6
              c4.5,0,8,1.3,10.6,3.8c2.5,2.5,3.8,6.1,4,10.8c0,3-0.2,5.2-0.5,6.8H84.7c0.1,4.8,1.2,8.7,3.4,11.7c2.1,2.9,5.2,4.4,9.1,4.4
              c1.9,0,4-0.4,6.1-1.1c2.1-0.7,3.8-1.5,4.9-2.5l1.4,3.2c-1.4,1.5-3.5,2.7-6.3,3.8c-2.8,1.1-5.6,1.6-8.5,1.6
              C88.9,311.6,84.4,309.7,81.4,305.9z M102.3,286.6c0.1-1,0.2-2.1,0.2-3.1c0-3.2-0.7-5.7-2-7.5c-1.3-1.8-3.3-2.7-6.1-2.7
              c-2.8,0-5.1,1-6.8,3.1s-2.6,5.5-2.9,10.3H102.3z"/>
            <path fill="#FFFFFF" d="M123.1,309.4c-2.4-1.5-4.4-3.7-5.8-6.7c-1.4-3-2.2-6.7-2.2-11.2c0-4.1,0.9-7.9,2.6-11.3
              c1.7-3.4,4.2-6.1,7.3-8.1c3.2-2,7-3,11.3-3c2.5,0,4.7,0.3,6.8,0.8v-13.7l-7.5-0.9v-3.1l13.2-1.3h0.2l1.6,1.2v54.9h4.2v3.4
              c-1.6,0.3-2.9,0.6-4.1,0.8c-1.1,0.2-2.4,0.3-3.6,0.3c-1.3,0-2.2-0.2-2.7-0.7c-0.5-0.4-0.8-1.4-0.8-2.8v-2.3
              c-1.4,1.7-3.2,3-5.4,4.2c-2.2,1.1-4.6,1.7-7.2,1.7C128.3,311.6,125.6,310.9,123.1,309.4z M139.4,305.2c1.7-0.9,2.9-1.8,3.7-3
              v-26.7c-0.4-0.6-1.3-1.1-2.7-1.6c-1.4-0.5-3-0.7-4.6-0.7c-3.8,0-6.8,1.4-9.1,4.3c-2.3,2.9-3.4,7.4-3.4,13.4c0,5.3,1,9.2,3,11.7
              c2,2.5,4.6,3.8,7.7,3.8C136,306.4,137.8,306,139.4,305.2z"/>
            <path fill="#FFFFFF" d="M165.8,275.1h-6.9v-3.4l6.9-1.4v-3.4c0-3,0.8-5.8,2.4-8.3c1.6-2.5,3.7-4.5,6.2-6c2.6-1.5,5.3-2.2,8.1-2.2
              c1.7,0,3.1,0.2,4.3,0.7v7.3c-0.4-0.4-1.3-0.8-2.6-1.1c-1.3-0.4-2.7-0.5-4.3-0.5c-2.3,0-4,0.6-5,1.8c-1,1.2-1.6,3.3-1.6,6.2v5.8
              h10.1v4.7h-10.1v31.6l8.1,0.6v3.6h-21.9v-3.6l6.2-0.7V275.1z"/>
            <path fill="#FFFFFF" d="M188.6,279c1.8-3.2,4.2-5.7,7.1-7.3c2.9-1.7,6.1-2.5,9.5-2.5c6.1,0,10.8,1.9,13.8,5.8
              c3.1,3.9,4.6,9,4.6,15.3c0,4.4-0.9,8.3-2.7,11.5c-1.8,3.2-4.2,5.7-7.1,7.3c-2.9,1.7-6.1,2.5-9.5,2.5c-6.1,0-10.8-1.9-13.8-5.8
              c-3.1-3.9-4.6-9-4.6-15.3C185.9,286.1,186.8,282.3,188.6,279z M212.6,303.3c1.8-2.8,2.7-6.9,2.7-12.3c0-5.4-0.8-9.7-2.5-12.9
              c-1.7-3.2-4.3-4.7-8-4.7c-3.4,0-6,1.4-7.8,4.2c-1.8,2.8-2.7,6.9-2.7,12.3c0,5.4,0.9,9.7,2.6,12.9c1.7,3.2,4.4,4.7,8,4.7
              C208.2,307.5,210.8,306.1,212.6,303.3z"/>
            <path fill="#FFFFFF" d="M227.5,307.2l5.5-0.4v-30.9l-5.3-1.3v-3.9l10.1-1.3h0.1l1.6,1.3v1.3l-0.2,5.7h0.2c0.5-0.8,1.4-1.9,2.6-3.2
              s2.9-2.5,4.9-3.6c2-1.1,4.2-1.6,6.5-1.6c1,0,1.8,0.1,2.4,0.4v8.2c-0.3-0.2-0.9-0.5-1.6-0.7c-0.8-0.2-1.6-0.3-2.5-0.3
              c-4,0-7.8,1.2-11.3,3.7v26.3l9.3,0.6v3.5h-22.4V307.2z"/>
            <path fill="#FFFFFF" d="M266.4,309.4c-2.4-1.5-4.4-3.7-5.8-6.7c-1.4-3-2.2-6.7-2.2-11.2c0-4.1,0.9-7.9,2.6-11.3
              c1.7-3.4,4.2-6.1,7.3-8.1c3.2-2,7-3,11.3-3c2.5,0,4.7,0.3,6.8,0.8v-13.7l-7.5-0.9v-3.1l13.2-1.3h0.2l1.6,1.2v54.9h4.2v3.4
              c-1.6,0.3-2.9,0.6-4.1,0.8c-1.1,0.2-2.4,0.3-3.6,0.3c-1.3,0-2.2-0.2-2.7-0.7c-0.5-0.4-0.8-1.4-0.8-2.8v-2.3
              c-1.4,1.7-3.2,3-5.4,4.2c-2.2,1.1-4.6,1.7-7.2,1.7C271.5,311.6,268.8,310.9,266.4,309.4z M282.7,305.2c1.7-0.9,2.9-1.8,3.7-3
              v-26.7c-0.4-0.6-1.3-1.1-2.7-1.6c-1.4-0.5-3-0.7-4.6-0.7c-3.8,0-6.8,1.4-9.1,4.3c-2.3,2.9-3.4,7.4-3.4,13.4c0,5.3,1,9.2,3,11.7
              c2,2.5,4.6,3.8,7.7,3.8C279.2,306.4,281,306,282.7,305.2z"/>
            <path fill="#FFFFFF" d="M331.9,308.2c-3.8-2.3-6.6-5.6-8.6-9.8c-1.9-4.2-2.9-9.1-2.9-14.8c0-5.5,1.1-10.3,3.4-14.5
              c2.3-4.2,5.4-7.4,9.4-9.8c4-2.3,8.5-3.5,13.4-3.5c3.8,0,8.3,0.6,13.5,1.8l2.4,0.4l-0.7,13.2H357l-1.8-9.3
              c-0.7-0.6-1.9-1.1-3.5-1.5c-1.7-0.4-3.7-0.6-6.2-0.6c-3.1,0-5.9,0.9-8.3,2.7c-2.4,1.8-4.3,4.4-5.7,7.9
              c-1.4,3.5-2.1,7.6-2.1,12.4c0,4.6,0.6,8.7,1.8,12.5c1.2,3.8,3.1,6.7,5.6,9c2.5,2.2,5.6,3.3,9.2,3.3c2.6,0,4.6-0.2,6-0.7
              c1.4-0.4,2.6-1.1,3.5-1.9l3.1-7.2l4.6,0.6l-1.8,10.4c-1,0-2.5,0.4-4.5,1c-1.8,0.5-3.4,1-5.1,1.3c-1.6,0.3-3.7,0.5-6.1,0.5
              C340.3,311.6,335.7,310.5,331.9,308.2z"/>
            <path fill="#FFFFFF" d="M372.7,275.9l-5.6-1.3v-3.9l11.3-1.4h0.1l1.7,1.3v36l5.8,0.6v3.6h-19.4v-3.6l5.9-0.7V275.9z M372.1,261.7
              c-0.8-0.9-1.2-2-1.2-3.4c0-1.6,0.5-2.8,1.4-3.9s2.3-1.5,3.9-1.5c1.7,0,2.9,0.4,3.7,1.3c0.8,0.9,1.2,2,1.2,3.4
              c0,1.7-0.5,3-1.4,3.9c-1,1-2.3,1.5-4,1.5h-0.1C374.1,263,372.9,262.5,372.1,261.7z"/>
            <path fill="#FFFFFF" d="M394.5,309.7c-1.4-1.3-2-3.4-2-6.5v-28.1h-5.3v-3.3c0.2,0,0.9-0.2,2-0.5c1.1-0.3,1.9-0.6,2.3-0.8
              c0.8-0.4,1.4-1.3,1.8-2.6c0.4-0.9,0.9-2.4,1.4-4.5c0.5-2,0.9-3.3,0.9-3.7h4.3l0.1,10.5h12.2v4.8h-12.2v22.4
              c0,2.8,0.1,4.7,0.3,5.8c0.2,1.1,0.5,1.9,1.1,2.2c0.6,0.3,1.5,0.5,2.9,0.5c1.3,0,2.8-0.2,4.3-0.5c1.6-0.3,2.8-0.7,3.7-1.1
              l1.1,3.3c-1.2,0.9-3.1,1.7-5.7,2.6c-2.6,0.8-5,1.2-7.1,1.2C397.9,311.6,395.9,310.9,394.5,309.7z"/>
            <path fill="#FFFFFF" d="M422.5,328.9c-0.8-0.1-1.4-0.2-1.7-0.4v-6.4c0.3,0.2,0.9,0.3,1.7,0.4c0.9,0.1,1.7,0.1,2.4,0.1
              c2.3,0,4.4-0.8,6.2-2.4c1.8-1.6,3.5-4.8,5.2-9.4h-3.5l-13.9-36.2l-3.4-0.9v-3.4h17.6v3.4l-5.6,0.9l7.2,21.6l3.4,10.7l3.1-10.7
              l6.5-21.5l-4.9-0.9v-3.4h14.7v3.4l-3.7,0.9c-7.2,21.9-11.4,34.4-12.6,37.3c-1.7,4.2-3.2,7.5-4.5,9.8c-1.4,2.4-3,4.2-4.8,5.4
              c-1.9,1.2-4.1,1.9-6.9,1.9C424.1,329,423.3,329,422.5,328.9z"/>
            <path fill="#FFFFFF" d="M488.8,308.2c-3.8-2.3-6.6-5.6-8.6-9.8c-1.9-4.2-2.9-9.1-2.9-14.8c0-5.5,1.1-10.3,3.4-14.5
              c2.3-4.2,5.4-7.4,9.4-9.8c4-2.3,8.5-3.5,13.4-3.5c3.8,0,8.3,0.6,13.5,1.8l2.4,0.4l-0.7,13.2h-4.9L512,262
              c-0.7-0.6-1.9-1.1-3.5-1.5c-1.7-0.4-3.7-0.6-6.2-0.6c-3.1,0-5.9,0.9-8.3,2.7c-2.4,1.8-4.3,4.4-5.7,7.9
              c-1.4,3.5-2.1,7.6-2.1,12.4c0,4.6,0.6,8.7,1.8,12.5c1.2,3.8,3.1,6.7,5.6,9c2.5,2.2,5.6,3.3,9.2,3.3c2.6,0,4.6-0.2,6-0.7
              c1.4-0.4,2.6-1.1,3.5-1.9l3.1-7.2l4.6,0.6l-1.8,10.4c-1,0-2.5,0.4-4.5,1c-1.8,0.5-3.4,1-5.1,1.3c-1.6,0.3-3.7,0.5-6.1,0.5
              C497.1,311.6,492.5,310.5,488.8,308.2z"/>
            <path fill="#FFFFFF" d="M526.8,279c1.8-3.2,4.2-5.7,7.1-7.3c2.9-1.7,6.1-2.5,9.5-2.5c6.1,0,10.8,1.9,13.8,5.8
              c3.1,3.9,4.6,9,4.6,15.3c0,4.4-0.9,8.3-2.7,11.5c-1.8,3.2-4.2,5.7-7.1,7.3c-2.9,1.7-6.1,2.5-9.5,2.5c-6.1,0-10.8-1.9-13.8-5.8
              c-3.1-3.9-4.6-9-4.6-15.3C524.1,286.1,525,282.3,526.8,279z M550.7,303.3c1.8-2.8,2.7-6.9,2.7-12.3c0-5.4-0.8-9.7-2.5-12.9
              c-1.7-3.2-4.3-4.7-8-4.7c-3.4,0-6,1.4-7.8,4.2c-1.8,2.8-2.7,6.9-2.7,12.3c0,5.4,0.9,9.7,2.6,12.9c1.7,3.2,4.4,4.7,8,4.7
              C546.4,307.5,548.9,306.1,550.7,303.3z"/>
            <path fill="#FFFFFF" d="M596.2,310.7c-0.7-0.5-1-1.4-1-2.6v-2.5c-1.6,1.6-3.6,3-6,4.2s-4.8,1.8-7.3,1.8c-4.5,0-7.7-1.2-9.6-3.7
              c-1.9-2.4-2.8-6.5-2.8-12.1v-21.2l-4.1-1.2v-3.2l10.2-0.9h0.1l1.4,0.9v25.1c0,2.8,0.2,5,0.6,6.6c0.4,1.6,1.1,2.8,2.2,3.5
              c1,0.8,2.6,1.1,4.6,1.1c1.9,0,3.8-0.4,5.5-1.2c1.7-0.8,3.2-1.8,4.5-2.9v-27.7l-5.3-1.2v-3.2l11.1-0.9h0.1l1.7,0.9v36.8h4.4
              l-0.1,3.3c-1.4,0.3-2.5,0.6-3.5,0.8c-1,0.2-2.2,0.3-3.5,0.3C597.8,311.5,596.8,311.2,596.2,310.7z"/>
            <path fill="#FFFFFF" d="M615.6,275.9l-5.3-1.3v-3.9l10.4-1.3h0.2l1.5,1.3v3.1l-0.1,1.8c1.8-1.6,4.1-3,6.9-4.2
              c2.9-1.3,5.6-1.9,8.3-1.9c3.1,0,5.4,0.6,7.1,1.8c1.7,1.2,2.9,3,3.5,5.4c0.7,2.4,1,5.7,1,9.8v20.5l5.1,0.5v3.7h-17.3v-3.6
              l4.5-0.6v-20.5c0-2.9-0.2-5.1-0.6-6.8c-0.4-1.6-1.1-2.8-2.2-3.7s-2.6-1.2-4.7-1.2c-1.8,0-3.6,0.4-5.6,1.3
              c-2,0.9-3.8,1.9-5.3,3.1v27.7l5,0.7v3.6H611v-3.6l4.6-0.7V275.9z"/>
            <path fill="#FFFFFF" d="M659.3,279.8c1.6-3.3,3.9-5.9,7-7.8c3.1-1.9,6.8-2.9,11.1-2.9c2.1,0,3.9,0.2,5.4,0.6
              c1.5,0.4,3.3,0.9,5.4,1.6l-0.3,11.3h-4.7l-1.6-7.5c-0.3-1.4-2.1-2-5.6-2c-3.4,0-6.1,1.4-8.1,4.1c-2,2.8-3,6.7-3,11.7
              c0,5.7,1.1,10.1,3.3,13.1c2.2,3,5.1,4.5,8.7,4.5c2,0,4-0.3,5.8-0.9c1.8-0.6,3.4-1.4,4.7-2.3l1.4,2.9c-1.3,1.4-3.3,2.6-5.8,3.7
              c-2.6,1.1-5.2,1.6-7.8,1.6c-4,0-7.4-0.9-10.1-2.7c-2.8-1.8-4.8-4.3-6.2-7.4c-1.4-3.1-2.1-6.7-2.1-10.7
              C656.9,286.8,657.7,283.1,659.3,279.8z"/>
            <path fill="#FFFFFF" d="M699.8,275.9l-5.6-1.3v-3.9l11.3-1.4h0.1l1.7,1.3v36l5.8,0.6v3.6h-19.4v-3.6l5.9-0.7V275.9z M699.2,261.7
              c-0.8-0.9-1.2-2-1.2-3.4c0-1.6,0.5-2.8,1.4-3.9s2.3-1.5,3.9-1.5c1.7,0,2.9,0.4,3.7,1.3c0.8,0.9,1.2,2,1.2,3.4
              c0,1.7-0.5,3-1.4,3.9c-1,1-2.3,1.5-4,1.5h-0.1C701.1,263,699.9,262.5,699.2,261.7z"/>
            <path fill="#FFFFFF" d="M723.4,256.3l-5.9-0.8v-3.1l11.8-1.4h0.1l1.6,1.1v54.6l6.2,0.6v3.6h-19.7v-3.6l5.9-0.7V256.3z"/>
          </g>
        </g>
        <g>
          <polygon fill="#FFFFFF" points="330.8,205.8 333.9,215.4 344,215.4 335.9,221.4 339,231 330.8,225 322.6,231 325.7,221.4 
            317.5,215.4 327.7,215.4 			"/>
          <polygon fill="#FFFFFF" points="408.3,205.8 411.4,215.4 421.6,215.4 413.4,221.4 416.5,231 408.3,225 400.1,231 403.2,221.4 
            395.1,215.4 405.2,215.4 			"/>
          <polygon fill="#FFFFFF" points="369.6,205.8 372.7,215.4 382.8,215.4 374.6,221.4 377.7,231 369.6,225 361.4,231 364.5,221.4 
            356.3,215.4 366.4,215.4 			"/>
        </g>
        <g>
          <g>
            <rect x="2.1" y="218.4" fill="#FFFFFF" width="290" height="2"/>
          </g>
          <g>
            <rect x="448.1" y="218.4" fill="#FFFFFF" width="289" height="2"/>
          </g>
        </g>
        <g>
          <path fill="#FFFFFF" d="M182.9,3.4c0.1-2.1,0.4-3.4,1.2-3.4c0.6,0,0.9,0.2,2.7,1.7c3,2.5,6.6,6,22.7,20.7c7.2,6.6,12,11.3,13.9,13
            L223,9.1c-0.1-3.9-0.5-5.8-3.3-6.3c-1-0.2-2.1-0.3-3.1-0.4c-0.9,0-1.3-0.4-1.3-0.7c0-0.5,0.6-0.7,1.4-0.7c2.8,0,7.4,0.2,10.2,0.2
            c1.5,0,5.1-0.2,8-0.2c1.1,0,1.5,0.2,1.5,0.6c0,0.4-0.4,0.8-1.2,0.8c-0.9,0.1-1.3,0.1-1.8,0.2c-2.3,0.3-3,1.5-3.2,5.8
            c-0.6,14.1-0.6,28.2-0.8,42.3c0,3.6-0.5,4.4-1.4,4.4c-1.1,0-3.3-1.7-7.9-5.7c-4.8-4.2-11-9.8-15.2-13.6
            c-3.9-3.6-11-10.2-16.1-15.2l0.7,24c0.1,5.1,0.4,7,2.4,7.7c1.1,0.4,2.3,0.4,3.7,0.5c1.1,0.1,1.4,0.3,1.4,0.7
            c0,0.6-0.6,0.7-1.7,0.7c-2.5,0-4.7-0.1-9.1-0.2c-1.6,0-6.8,0.2-9.6,0.2c-1,0-1.5-0.1-1.5-0.7c0-0.4,0.4-0.7,1.3-0.7
            c0.6-0.1,1.5-0.1,2.3-0.2c2.7-0.4,3.1-2.2,3.3-8.7C182.6,29.4,182.5,12.4,182.9,3.4z"/>
          <path fill="#FFFFFF" d="M249.3,25.8c0-9.5,0-11.1-0.2-13c-0.1-1.6-0.6-2.7-2.1-3c-0.4-0.1-1.1-0.2-2-0.3c-0.8-0.1-1.2-0.3-1.2-0.7
            c0-0.4,0.2-0.7,1.4-0.7c2.5,0,9.2,0.2,9.4,0.2c0.4,0,8.3-0.2,10.7-0.2c1.1,0,1.3,0.3,1.3,0.7c0,0.5-0.5,0.6-1.3,0.7
            c-0.6,0.1-0.7,0.1-1.4,0.2c-1.7,0.4-2.2,1-2.5,3.1c-0.2,2-0.2,3.4-0.2,12.9v10.7c0,4.6,0.1,10.4,0.4,13c0.1,1.5,0.6,2.6,2.5,3
            c0.8,0.2,1.9,0.2,2.6,0.3c1.1,0,1.3,0.4,1.3,0.8c0,0.3-0.3,0.6-1.3,0.6c-4.4,0-11.6-0.2-12-0.2c-0.1,0-7.5,0.2-9.4,0.2
            c-1.1,0-1.5-0.2-1.5-0.7c0-0.4,0.4-0.7,1.1-0.7c0.6-0.1,1.2-0.1,2-0.2c1.3-0.3,1.9-1.5,2-3.3c0.4-2.5,0.4-6.7,0.4-12.8V25.8z"/>
          <path fill="#FFFFFF" d="M281.9,48.4c-6.3-5.1-8-11.3-8-17.9c0-4.1,1.5-11.8,7.9-16.9c5.6-4.5,12-6.2,21-6.2c3.5,0,6.6,0.4,9,0.7
            c3,0.5,4.2,0.8,6.1,0.8c0.7,0.1,0.7,0.5,0.7,0.8c0,0.5-0.1,2.2-0.3,4.3c-0.1,2-0.1,5.6-0.3,7.3c-0.1,0.9-0.3,1.5-0.8,1.5
            c-0.5,0-0.7-0.5-0.8-1.3c-0.1-1.2-0.1-3.7-2.3-6c-2.2-2.3-6.5-3.8-12.6-3.8c-3.3,0-6.5,0.8-8.7,2.7c-3.7,3-5.5,8.4-5.5,15
            c0,12.1,6.5,20.7,17.3,20.7c5,0,8-0.4,10.5-3c0.8-0.8,1.6-2.5,2-4.4c0.3-1.3,0.5-1.5,1-1.5c0.4,0,0.6,0.4,0.6,1.1
            c0,1.2-0.8,6.5-1.3,9.5c-0.2,1.1-0.7,1.7-1.7,2c-2.5,1-6.8,1.3-10.8,1.3C296.9,55,288.7,53.8,281.9,48.4z"/>
          <path fill="#FFFFFF" d="M326.7,31.4c0-12.7,8.7-24.1,27.2-24.1C370,7.3,380,18.1,380,30c0,14.3-9.9,25-26.5,25
            C334.8,55,326.7,42.2,326.7,31.4z M366.5,32.8c0-9.3-3.4-21.4-14.8-21.4c-5.4,0-11.5,2.7-11.5,17c0,11.2,4.5,22.4,15,22.4
            C359.1,50.8,366.5,49.3,366.5,32.8z"/>
          <path fill="#FFFFFF" d="M404.6,36.6c0,5.8-0.2,10.2,0.8,11.5c0.9,1.3,2,1.5,7.3,1.5c3.5,0,5.2-0.1,7-0.7c1.8-0.7,3.1-2.2,3.9-5.6
            c0.1-0.7,0.4-1.3,0.9-1.3c0.4,0,0.6,0.4,0.6,1.3c0,1.5-0.9,7.7-1.5,9.4c-0.6,1.6-1,1.6-3.3,1.6c-9.5,0-15.3-0.4-22-0.4
            c-3,0-6.1,0.2-9.7,0.2c-1,0-1.5-0.2-1.5-0.8c0-0.3,0.4-0.6,1.2-0.6c0.6-0.1,0.8-0.1,1.5-0.1c2.5-0.4,2.8-1.4,3-7
            c0-2.4,0.1-5.8,0.1-9.1V25.8c0-10.1-0.1-10.8-0.2-12.8c-0.1-1.7-0.7-2.7-2.2-3.1c-0.6-0.1-1.4-0.3-2.3-0.4
            c-0.7-0.1-1.3-0.2-1.3-0.7c0-0.5,0.5-0.7,1.8-0.7c3.8,0,6.9,0.2,9.6,0.2c0.4,0,8.5-0.2,10.8-0.2c1.1,0,1.4,0.3,1.4,0.6
            c0,0.6-0.6,0.8-1.1,0.8c-0.7,0.1-1,0.1-1.9,0.3c-1.8,0.3-2.2,1.4-2.4,3.6c-0.1,2-0.2,2.9-0.2,13.1V36.6z"/>
          <path fill="#FFFFFF" d="M435.1,25.8c0-10.1-0.1-11.2-0.2-13.2c-0.1-1.5-0.8-2.5-2.2-2.8c-0.6-0.1-1.3-0.1-1.9-0.2
            c-0.9-0.1-1.1-0.4-1.1-0.8s0.4-0.6,1.3-0.6c3.8,0,6.7,0.2,9.4,0.2c1.1,0,17.3-0.1,18.3-0.1c1.2,0,2.5-0.1,3.6-0.3
            c0.4-0.1,0.8-0.2,1-0.2c0.4,0,0.5,0.3,0.4,0.6c-0.1,0.8-0.4,1.5-0.5,3c-0.1,1.5-0.1,2.8-0.4,6.6c0,0.5-0.3,1-0.7,1
            c-0.4,0-0.6-0.3-0.7-0.8c-0.1-0.8-0.1-1.3-0.4-2.2c-0.4-1.4-1.1-2.2-4.1-2.5c-2.3-0.1-7.5-0.3-9.4-0.3c-0.4,0-0.5,0.2-0.5,0.6
            v12.9c0,0.4,0.1,0.6,0.5,0.6c1.9,0,6.3,0,8.7-0.1c3.4-0.1,4.3-0.4,5.1-1.5c0.3-0.6,0.6-0.8,0.8-0.8c0.4,0,0.5,0.4,0.5,0.8
            c0,0.7-0.4,3.4-0.5,5.6c-0.1,1.3-0.1,4.6-0.1,5.3c0,0.8-0.2,1.1-0.6,1.1c-0.4,0-0.6-0.3-0.7-1c-0.1-0.4-0.1-0.8-0.1-1.2
            c-0.4-1.9-1.1-3-4.4-3.2c-2-0.1-6.7-0.2-8.7-0.2c-0.4,0-0.4,0.1-0.4,0.5v4.4c0,2-0.1,4.9,0,7.4c0.1,4.3,0.8,5.3,8,5.3
            c1.6,0,4.1-0.1,5.8-0.7c1.8-0.7,2.5-2.2,3-5c0.1-0.7,0.4-1.1,0.8-1.1c0.5,0,0.6,0.5,0.6,1.3c0,2.7-0.6,7.6-0.9,8.8
            c-0.5,1.5-1,1.6-3.3,1.6c-9.4,0-13.9-0.4-20.6-0.4c-3,0-6,0.2-9.6,0.2c-1.2,0-1.5-0.4-1.5-0.8c0-0.3,0.2-0.6,1.3-0.6
            c0.6-0.1,1.1-0.1,1.8-0.2c2-0.4,2.2-2.7,2.5-7c0-2.3,0.1-5.7,0.1-9.1V25.8z"/>
        </g>
      </g>
    </g>
  </svg>

)

export default Logo
